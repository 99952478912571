import React from 'react'
import { format, isPast } from 'date-fns'

const DemoDaySummary = ({ launchDate, displayNumber, description, slug, }) => {
  const launchMonth = format(launchDate, 'MMM')
  const launchYear = format(launchDate, 'yyyy')

  return (
    <div className="demo-day">
      <div className="date-block">
        <p>
          <span>{launchMonth}</span>
          {launchYear}
        </p>
      </div>
      <div className="text-block">
        <h2>Demo Day {displayNumber}</h2>
        <p>{description}</p>
      </div>
      <div className="action-items">
        {isPast(launchDate) ? (
          <a
            className="button-white-outline"
            aria-label="View Details"
            href={`/demo_days/${slug}`}
          >
            View Details
          </a>
        ) : (
            <p>Scheduled for {`${launchMonth} ${launchYear}`}</p>
          )}
      </div>
    </div>
  )
}

export default DemoDaySummary
